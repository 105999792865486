<template>
    <b-container>
        <h4>
            <router-link :to="{ name: 'signup_settings', params: { id: id } }" class="mr-2">
                <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
            </router-link>
            Member Sign-ups
        </h4>
        <b-table
            id="ticket-man-table"
            :key="show.join(',')"
            class="mt-4"
            :busy="isBusy"
            :fields="fields"
            :items="getMembers"
            :filter="filter"
            :no-provider-filtering="true"
            :no-provider-sorting="true"
            empty-text="There are no results that match your criteria."
            empty-filtered-text="There are no results that match your criteria."
            head-variant="dark"
            bordered
        >
            <template #cell(person_list)="{ item: { other_list, person_list } }">
                <span v-if="person_list">
                    {{ formatPersonList(person_list) }}
                </span>
                <div>
                    <b>Others</b> &mdash; <em>{{ formatOtherList(other_list) || "No others" }}</em>
                </div>
            </template>
            <template #cell(action)="row">
                <div class="float-right">
                    <b-button
                        variant="link"
                        @click="confirmDelete(row.item)"
                    >
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </b-button>
                </div>
            </template>
        </b-table>
    </b-container>
</template>
<script>
export default {
    props: ["id"],
    data() {
        return {
            isBusy: false,
            show  : [1],
            count : {},
            filter: "",
            fields: [
                { key: "user.person.last_name", label: "Last Name", formatter: (value, key, item) => {
                    if (value == null) return item.first_name;
                    return value;
                }, sortable: true },
                { key: "user.person.first_name", label: "First Name", formatter: (value, key, item) => {
                    if (value == null) return item.first_name;
                    return value;
                }, sortable: true },
                { key: "person_list", label: "Person List" },
                { key: "date_submitted", label: "Sign up Date", formatter: (value) => {
                    return this.$moment(value).local().format("LLLL");
                }, sortable: true }
            ]
        };
    },
    methods: {
        getAge(birthdate) {
            return this.$moment().diff(birthdate, "years");
        },
        formatPersonList(person_list) {
            return person_list.map(
                ({ person: { first_name, last_name, gender, birthdate, position } }) => {
                    return position == 0 ?
                        `${first_name} ${last_name} (${gender}, ${this.getAge(birthdate)})` :
                        `${first_name} ${last_name}`
                }).join(', ');
        },
        formatOtherList(other_list) {
            return (JSON.parse(other_list || "[]") || []).join(", ");
        },
        getMembers() {
            return  this.$api.get(`/api/sign-ups/admin/members/${this.id}/fetch`, { params: { status: this.show } }).then((response) => {
                        this.count = {}; // response.data.count;
                        return response.data.members;
                    }).catch(() => {
                        return [];
                    });
        },
    }
}
</script>